<template>
  <b-card class="px-2 py-2">
    <b-row class="align-items-end mb-2">
      <b-col
        cols="4"
        class="mb-1 mb-sm-0">
        <FilterDropdown
          v-model="filter.status"
          label="Status"
          placeholder="Status"
          :options="options.status"
        />
      </b-col>
      <b-col
        cols="8"
      >
        <b-button
          variant="primary"
          @click="submitExport()">
          Download
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import FilterDropdown from '@/components/Filter/FilterDropdown.vue'
import ReportProvider from '@/resources/ReportProvider'
import dayjs from 'dayjs'

const ReportService = new ReportProvider()

export default {
  components: {
    FilterDropdown
  },
  data () {
    return {
      isDeleteModalActive: false,
      filter: {
        startDate: dayjs().format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD'),
        userIds: [],
        userTeamId: [],
        userGroupId: []
      },
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 10
      },
      fields: [
        { key: 'ticketStatus', label: 'สถานะ', sortable: false },
        { key: 'code', label: 'เลขที่รับเรื่อง', sortable: false },
        { key: 'createdAt', label: 'วันที่แจ้งเรื่อง', sortable: false },
        { key: 'closedDate', label: 'วันที่ปิดงาน', sortable: false },
        { key: 'tickeType.name', label: 'ประเภท', sortable: false },
        { key: 'contactChannel.name', label: 'ช่องทางการแจ้งเรื่อง', sortable: false },
        { key: 'subject', label: 'หัวข้อ', sortable: false },
        { key: 'subjects', label: 'เรื่อง', sortable: false },
        { key: 'ticketDetail', label: 'รายละเอียด', sortable: false },
        { key: 'ticketResolve', label: 'การแก้ปัญหา', sortable: false },
        { key: 'customerName', label: 'ผู้แจ้งเรื่อง', sortable: false },
        { key: 'progressBy', label: 'ผู้รับเรื่อง', sortable: false }
      ],
      items: [],
      selectedItem: {},
      initialTicketType: {},
      deleteItem: {},
      ticketType: [],
      startDate: '',
      endDate: '',
      options: {
        status: ['active', 'inactive']
      }
    }
  },
  methods: {
    exportCSV (data) {
      const items = data?.map((item) => ({
        'Code-Full': item?.codeFull,
        'CategoryLv1_ID': item?.categoryLv1Id,
        'CategoryLv1': item?.categoryLv1,
        'CategoryLv2_ID': item?.categoryLv2Id,
        'CategoryLv2': item?.categoryLv2,
        'CategoryLv3_ID': item?.categoryLv3Id,
        'CategoryLv3 ': item?.categoryLv3,
        'CategoryLv4_ID': item?.categoryLv4Id,
        'CategoryLv4': item?.categoryLv4,
        'CategoryLv5_ID': item?.categoryLv5Id,
        'CategoryLv5': item?.categoryLv5,
        'CategoryLv6_ID': item?.categoryLv6Id,
        'CategoryLv6': item?.categoryLv6,
        'SLA: Resolved Day': item?.slaResolvedDay,
        'SLA: Resolved Hour': item?.slaResolvedHour,
        'SLA: Resolved Min': item?.slaResolvedMin,
        'Priority (ความสำคัญ)': item?.priority,
        'Urgency (ความเร่งด่วน)': item?.urgency,
        'Impact (ผลกระทบกับ operation)': item?.impact,
        'Status Active/Inactive': item?.status,
        'Update Date': item?.updatedDate
      }))
      const replacer = (key, value) => (value === null ? '' : value) // specify how you want to handle null values here
      const header = Object.keys(items[0])
      const csv = [
        header.join(','), // header row first
        ...items.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','))
      ].join('\r\n')
      const universalBOM = '\uFEFF'
      const csvContent = `data:text/csv; charset=utf-8,${encodeURIComponent(universalBOM + csv)}`
      // const encodedUri = encodeURIComponent(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', csvContent)
      link.setAttribute('download', `CATEGORIES_REPORT_${new Date().toLocaleString('en-US')}.csv`)
      document.body.appendChild(link)
      link.click()
    },
    async submitExport () {
      try {
        const { data } = await ReportService.categoriesReport(this.filter)
        this.exportCSV(data)
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-select {
  background: white;
}
.card-body {
  padding: 0;
}
::v-deep input.vs__search::placeholder {
  color: #B9B9C3;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
</style>
